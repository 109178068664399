.filter-container {
  margin-bottom: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.flex {
  display: flex;
}

.dropdown {
  max-width: 11rem;
  flex-grow: 1;
}

.center {
  justify-content: center;
  align-items: center;
}
