.container {
  font-size: 10px;
  box-shadow: 0 0 20px rgb(8 21 66 / 5%);
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
}

.image {
  width: 8rem;
  height: 8rem;
}

.topContainer {
  display: flex;
  align-items: center;
}

.text {
  margin-bottom: 0px;
}

.f-rubik {
  font-family: 'Rubik';
}

.fs-1 {
  font-size: 1.1rem;
}

.fw-5 {
  font-weight: 500;
}

.ml-1 {
  margin-left: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.absolute-rb {
  position: absolute;
  bottom: 0;
  right: 0;
}

.absolute-rt {
  position: absolute;
  top: 0;
  right: 0;
}

.relative {
  position: relative;
}

.fs-2 {
  font-size: 1.3rem;
}

.fs-5 {
  font-size: 2rem;
}

.r-1 {
  right: 2rem;
}

.b-1 {
  bottom: 2rem;
}

.t-1 {
  top: 2rem;
}

.l-1 {
  left: 2rem;
}

.c-gray {
  color: #DADADA;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-red {
  background-color: #FF6666;
}

.bg-purple {
  background-color: #7D24E7;
}

.br-1 {
  border-radius: 0.5rem;
}

.p-1 {
  padding: 0.3rem;
}

.p-2 {
  padding: 0.5rem;
}

.c-white {
  color: white;
}

.flex {
  display: flex;
}

.yc {
  align-items: center;
}

.mt-2 {
  margin-top: 2rem;
}

.img-2 {
  width: 1.5rem;
  height: 1.5rem;
}

.r-circle {
  border-radius: 50%;
}

.ml-05 {
  margin-left: 0.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.pv-1 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.ph-2 {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}